import React from 'react';
import { classes, st } from './BookButton.st.css';
import { BookButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { useFormActions } from '../../Hooks/useFormActions';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { ISettingsContextValue } from '@wix/yoshi-flow-editor/tpa-settings';
import settingsParams from '../../settingsParams';
import { ActionLabels } from '@wix/ambassador-services-catalog-server/http';
import { BookErrorType, FormErrors } from '../../../../types/errors';
import { Spinner } from 'wix-ui-tpa/Spinner';
import stylesParams from '../../stylesParams';
import { hasErrorOfType } from '../../../../utils/errors/errors';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
  ServicePaymentDetails,
} from '../../../../types/types';
import { getContent } from '../../../../utils/content/content';
import { SelectedPaymentOption } from '@wix/ambassador-gateway/types';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { FormStatus } from '../../../../types/form-state';

export interface BookButtonProps {
  errors: FormErrors[];
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: SelectedPaymentOption[];
  status: FormStatus;
  selectedPaymentOption: PaymentOption;
  paymentDetails: ServicePaymentDetails;
  selectedPaymentType: SelectedPaymentOption;
  isBookingsOnEcom: boolean;
}
const BookButton: React.FC<BookButtonProps> = ({
  errors,
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
  selectedPaymentOption,
  status,
  paymentDetails,
  selectedPaymentType,
  isBookingsOnEcom,
}) => {
  const { t } = useTranslation();
  const { onSubmit, submitForm } = useFormActions();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const actionLabel = getActionLabel({
    selectedPaymentOption,
    isPendingApprovalFlow,
    actionLabels,
    paymentTypes,
    settings,
    paymentDetails,
    selectedPaymentType,
    isBookingsOnEcom,
  });
  const styles = useStyles();
  const fontSize = styles.get(stylesParams.mainButtonFont).size!;

  const shouldShowError = hasErrorOfType({ errors, errorType: BookErrorType });

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        size={SIZE.medium}
        className={classes.bookButton}
        data-hook={BookButtonDataHooks.ACTION_BUTTON}
        upgrade
        aria-pressed={status === FormStatus.PROCESSING_BOOK_REQUEST}
        type="submit"
        fullWidth={settings.get(settingsParams.stretchButtonToFullWidth)}
        onClick={() => {
          const submissionResponse = submitForm?.();
          if (submissionResponse) {
            onSubmit(submissionResponse);
          }
        }}
        disabled={status !== FormStatus.IDLE}
      >
        {status === FormStatus.PROCESSING_BOOK_REQUEST ? (
          <div className={classes.spinnerWrapper} style={{ height: fontSize }}>
            <Spinner
              data-hook={BookButtonDataHooks.SPINNER}
              className={classes.spinner}
              diameter={fontSize}
            />
          </div>
        ) : (
          t(actionLabel)
        )}
      </Button>
      <SectionMessage
        type={SectionMessageType.Error}
        text={shouldShowError ? t('app.server-errors.slot-not-available') : ''}
      />
    </div>
  );
};

const getActionLabel = ({
  selectedPaymentOption,
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
  settings,
  paymentDetails,
  selectedPaymentType,
  isBookingsOnEcom,
}: {
  selectedPaymentOption: PaymentOption;
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: SelectedPaymentOption[];
  settings: ISettingsContextValue;
  paymentDetails: ServicePaymentDetails;
  selectedPaymentType: SelectedPaymentOption;
  isBookingsOnEcom: boolean;
}): string => {
  if (selectedPaymentOption.id === ReservedPaymentOptionIds.BuyAPricingPlan) {
    return getContent({
      settings,
      settingsParam: settingsParams.chooseAPlanText,
    });
  } else if (isPendingApprovalFlow) {
    return (
      settings.get(settingsParams.requestBookingButtonText) ||
      actionLabels?.bookingRequestApprovalLabel!
    );
  } else if (isBookingsOnEcom) {
    if (
      selectedPaymentOption.id !== ReservedPaymentOptionIds.SingleSession ||
      paymentDetails.payNow === 0
    ) {
      return (
        settings.get(settingsParams.offlinePaymentButtonText) ||
        actionLabels?.offlinePaymentLabel!
      );
    } else {
      return (
        settings.get(settingsParams.onlinePaymentButtonText) ||
        actionLabels?.onlinePaymentLabel!
      );
    }
  } else {
    if (
      canBePaidOnline(paymentTypes) &&
      selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession
    ) {
      return (
        settings.get(settingsParams.onlinePaymentButtonText) ||
        actionLabels?.onlinePaymentLabel!
      );
    } else {
      return (
        settings.get(settingsParams.offlinePaymentButtonText) ||
        actionLabels?.offlinePaymentLabel!
      );
    }
  }
};

const canBePaidOnline = (paymentTypes: SelectedPaymentOption[]) =>
  paymentTypes?.includes(SelectedPaymentOption.ONLINE);

export default BookButton;
